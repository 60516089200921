<template>
  <v-container>
    <h1>Live Map</h1>
    <v-row>
      <v-col md="2" cols="6">
        <v-select label="VATSIM Options" :items="vatsimSelect" v-model="vatsimSelected" item-text="text" item-value="key" multiple />
      </v-col>
      <v-col md="2" cols="6">
        <v-select label="TFSK Options" :items="internalSelect" v-model="internalSelected" item-text="text" item-value="key" multiple />
      </v-col>
      <v-col md="2" cols="6">
        <v-select label="Map Base" :items="mapBaseOptions" v-model="chosenMapBase" item-text="text" item-value="key"/>
      </v-col>
      <v-col md="2" cols="6">
        <v-checkbox label="Show weather" v-model="weatherEnabled"/>
      </v-col>

    </v-row>
    <WebsocketData :urlInput="['allDataClients', 'vatsimData']" :enable-disable="[true, vatsimAreaATC || vatsimFlights || vatsimAirportATC || vatsimATIS]" :data.sync="websocketData" tag="span">
      <template slot-scope="{outData}">
        <template>
          <l-map ref="map1" :zoom="zoom" :center="liveMapCenter" style="height: 80vh; z-index: 0" :maxBoundsViscosity="1.0" :maxBounds="[[-90, -180], [90, 180]]" :options="{gestureHandling: true, worldCopyJump: true}" :minZoom="3" gestureHandling>
            <l-tile-layer :url="mapURL" :attribution="attribution" :noWrap="true"/>
            <l-tile-layer v-if="weatherMapURL != null" :url="weatherMapURL" layer-type="overlay" :visible="weatherEnabled"/>
            <template v-if="outData['vatsimData'] !== undefined && outData['vatsimData'] !== null">
              <template v-if="vatsimAreaATC">
                <l-polygon v-for="boundaryControllers in outData['vatsimData']['active_fir_boundaries']" :lat-lngs="boundaryControllers['airspace_points']" :fillOpacity="0.1" :weight="1">
                  <l-tooltip>
                    <div>
                      <h3>{{boundaryControllers.icao}} Control</h3>
                    </div>
                    <p v-for="controller in boundaryControllers.controllers" style="padding:0;margin:0;">
                      <b>{{controller.callsign}}</b> - {{controller.frequency}} - {{controller.name}}
                    </p>
                  </l-tooltip>
                </l-polygon>
              </template>

              <template v-if="vatsimAirportATC">
                <l-circle v-for="approachControllers in outData['vatsimData']['active_approach_controller']" :lat-lng="latitudeLongitude(approachControllers.latitude, approachControllers.longitude)" :radius="100000" :fillColor="'#FFFFFF'" :color="'#FFFFFF'" :weight="1">
                  <l-tooltip>
                    <div>
                      <h3>{{approachControllers.name}} Approach</h3>
                      <p v-for="controller in approachControllers.controllers" style="padding:0;margin:0;">
                        <b>{{controller.callsign}}</b> - {{controller.frequency}} - {{controller.name}}
                      </p>
                    </div>
                  </l-tooltip>
                </l-circle>
              </template>

              <template v-for="airportControllers in outData['vatsimData']['active_airport_controllers']">
                <template v-if="(vatsimAirportATC && airportControllers.controllers.filter(c => c['atis']).length < airportControllers.controllers.length) || (vatsimATIS && airportControllers.controllers.filter(c => c['atis']).length > 0)">
                  <l-marker :lat-lng="latitudeLongitude(airportControllers.latitude, airportControllers.longitude)" :icon="towerIconMap" :zIndexOffset="10000">
                    <l-tooltip>
                      <div>
                        <h3>{{airportControllers['airport_name']}}</h3>
                        <template v-for="controller in airportControllers.controllers">
                          <p v-if="(controller['atis'] && vatsimATIS) || (vatsimAirportATC && !controller['atis'])" style="padding:0;margin:0;">
                            <b>{{controller['callsign']}}</b> - {{controller['frequency']}} - {{controller['name']}}
                          </p>
                        </template>
                      </div>
                    </l-tooltip>
                  </l-marker>
                </template>
              </template>

              <template v-if="vatsimFlights">
                <v-rotated-marker v-for="(pilot, pilotIdx) in outData['vatsimData']['pilots']" :lat-lng="latitudeLongitude(pilot.latitude, pilot.longitude)" :icon="greenIcon" :rotationAngle="pilot.heading" @click="clickAction(['vatsimData', 'pilots'], 'vatsim', 'cid', pilot['cid'])">
                  <l-tooltip>
                    <span><b>[{{pilot['callsign']}}] {{pilot['name']}}</b> {{pilot['flight_plan']['aircraft']}}</span><br>
                    <span>{{pilot['flight_plan']['departure']}} > {{pilot['flight_plan']['arrival']}}</span><br>
                    <span>{{pilot['altitude']}}ft - {{pilot['groundspeed']}}Kts - {{pilot['heading']}}&#176;</span>
                  </l-tooltip>
                </v-rotated-marker>
              </template>
            </template>

            <template v-if="outData['allDataClients'] !== undefined && outData['allDataClients']">
              <template v-if="showRoute">
                <l-polyline :lat-lngs="routeLines" color="green"></l-polyline>
                <l-circle-marker v-for="(latLng, index) in routeLines" :lat-lng="latLng" :radius="4" color="red">
                  <l-tooltip>
                    {{routeItemLabels[index]}}
                  </l-tooltip>
                </l-circle-marker>
              </template>
              <template v-if="internalFlights">
                <v-rotated-marker v-for="(pilot, pilotIdx) in outData['allDataClients']" :lat-lng="latitudeLongitude(pilot['Data']['latitude'], pilot['Data']['longitude'])" :icon="pilot['Data']['Username'] === currentUser['username'] ? userIcon : icon" :rotationAngle="radians_to_degrees(pilot['Data']['heading_mag'])" @click="clickAction( ['allDataClients'], 'tfsk', 'Username', pilot['Username'])">
                  <l-tooltip>
                    <span v-if="pilot['Data']['source'] === 'MSFS'"><b>[{{pilot['Data']['atc_flight_number']}}] {{pilot['Data']['username']}}</b> {{pilot['Data']['aircraft_title']}}</span>
                    <span v-else><b>{{pilot['Data']['username']}}</b></span><br>
                    <span>{{pilot['Data']['altitude'].toFixed(2)}}ft - {{pilot['Data']['ground_speed'].toFixed(2)}}Kts - {{radians_to_degrees(pilot['Data']['heading_mag']).toFixed(2)}}&#176;</span>
                  </l-tooltip>
                </v-rotated-marker>
              </template>
            </template>
          </l-map>
        </template>
      </template>
    </WebsocketData>
    <LiveMapModal :pilot="selectedPilot" :dialog-state.sync="dialogState"/>
  </v-container>
</template>

<script>
import WebsocketData from "../components/WebsocketData";
import L from "leaflet";
import airplaneIcon from "@/assets/icons/airplane.png";
import airplaneIconGreen from "@/assets/icons/airplane-green.png";
import airplaneIconOrange from "@/assets/icons/airplane-orange.png"
import towerIcon from "@/assets/icons/tower.png"


import {
  LCircle,
  LCircleMarker,
  LControlAttribution,
  LIcon,
  LMap,
  LMarker,
  LPolygon,
  LPolyline,
  LPopup,
  LTileLayer,
  LTooltip
} from "vue2-leaflet";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import {mapGetters} from "vuex";
import Shared from "../components/Shared";
import LiveMapModal from "../components/LiveMapModal";

export default {
  name: "LiveMap",
  components: {
    LiveMapModal,
    WebsocketData,
    LMap,
    LTileLayer,
    LMarker,
    LCircleMarker,
    LCircle,
    LPopup,
    LTooltip,
    LControlAttribution,
    LIcon,
    LPolyline,
    LPolygon,
    'v-rotated-marker': Vue2LeafletRotatedMarker
  },
  data: function(){
    return {
      vatsimAreaATC: false,
      vatsimFlights: false,
      vatsimAirportATC: false,
      vatsimATIS: false,
      vatsimSelect: [
        {
          text: "Pilots",
          key: 0,
        },
        {
          text: "Area ATC",
          key: 1,
        },
        {
          text: "Airport ATC",
          key: 2,
        },
        {
          text: "ATIS",
          key: 3,
        }
      ],
      vatsimSelected: [],

      internalFlights: true,
      showRoute: true,
      internalSelect: [
        {
          text: "TFSK Pilots",
          key: 0,
        },
        {
          text: "Current Route",
          key: 1
        }
      ],
      internalSelected: [0, 1],
      mapBaseOptions: [
        {
          text: "Dark Map",
          key: 0
        },
        {
          text: "Light Map",
          key: 1
        },
        {
          text: 'Satellite',
          key: 2
        }
      ],
      chosenMapBase: 0,

      weatherEnabled: true,

      activeFlight: null,
      OFP: null,

      websocketData: null,

      zoom: 3,
      liveMapCenter: L.latLng(0, 0),

      // Dark 2 - https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png

      mapURLs: [
        'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
        'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
      ],

      prefix: "",
      attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors - <a href="https://carto.com/attributions">CARTO</a>',
      icon: L.icon({
        iconUrl: airplaneIcon,
        iconSize: [18, 18],
        iconAnchor: [9, 9]
      }),
      userIcon: L.icon({
        iconUrl: airplaneIcon,
        iconSize: [28,28],
        iconAnchor: [14, 14]
      }),
      greenIcon: L.icon({
        iconUrl: airplaneIconGreen,
        iconSize: [18, 18],
        iconAnchor: [9, 9]
      }),
      orangeIcon: L.icon({
        iconUrl: airplaneIconOrange,
        iconSize: [18, 18],
        iconAnchor: [9, 9]
      }),
      towerIconMap: L.icon({
        iconUrl: towerIcon,
        iconSize: [26, 26],
        iconAnchor: [13, 13]
      }),

      weatherUpdateTimer: null,
      latestWeatherTS: 0,

      dialogState: false,
      selectedPilotMapIdx: null,
      selectedPilotType: null,
      selectedPilotIdField: null,
      selectedPilotId: null,
    }
  },
  created: function(){
    this.updateWeatherTimestamp()
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + this.currentUser['tokenHash']
      },
    };
    fetch(this.$baseURL+"/"+this.currentUser['user']+"/activeFlight", requestOptions)
      .then(response => {
        if (response.ok){
          return response.json().then(data => {
            this.activeFlight = data

            if (this.activeFlight != null) {
              fetch(this.$baseURL + "/" + this.currentUser['user'] + "/flightOFP?flightID=" + this.activeFlight['id'], requestOptions)
                .then(response => response.json())
                .then(function (data) {
                  this.OFP = data['result']['OFP']
                }.bind(this))
            }
          })
        }
        console.error(response.status)
      })
  },
  watch: {
    vatsimSelected: function(val){
      this.vatsimFlights = val.includes(0)
      this.vatsimAreaATC = val.includes(1)
      this.vatsimAirportATC = val.includes(2)
      this.vatsimATIS = val.includes(3)
    },
    internalSelected: function(val){
      this.internalFlights = val.includes(0)
      this.showRoute = val.includes(1)
    },
    weatherEnabled: function(val){
      if (val){
        this.weatherUpdateTimer = setInterval(this.updateWeatherTimestamp, 10000)
      }else{
        clearInterval(this.weatherUpdateTimer)
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser'
    }),
    weatherMapURL: function(){
      if (this.latestWeatherTS === 0){
        return null
      }
      return 'https://tilecache.rainviewer.com/v2/radar/'+ this.latestWeatherTS +'/512/{z}/{x}/{y}/6/1_1.png'
    },
    mapURL: function(){
      return this.mapURLs[this.chosenMapBase]
    },
    generateFullRoute: function(){
      if (this.OFP === null){
        return null
      }
      return Shared.methods.generateFullRoute(this.OFP)
    },
    routeLines: function () {
      const route = this.generateFullRoute
      if (route === null){
        return []
      }
      return route.map(item => L.latLng(item['lat'], item['long']))
    },
    routeItemLabels: function (){
      const route = this.generateFullRoute
      if (route === null){
        return []
      }
      return route.map(item => item['ident'])
    },
    selectedPilot: function(){
      if (this.websocketData == null || this.selectedPilotMapIdx == null){
        return null
      }

      let data = this.websocketData

      this.selectedPilotMapIdx.forEach(d => {
        if (data === null){
          return null
        }
        data = data[d]
      })
      if (data === null){
        return null
      }
      data = data.filter(d => {
        return d[this.selectedPilotIdField] === this.selectedPilotId
      })

      if (data.length !== 1){
        return null
      }

      data = data[0]

      data['type'] = this.selectedPilotType

      return data
    }
  },
  methods: {
    latitudeLongitude: function(lat, lng){
      return L.latLng(lat, lng)
    },
    radians_to_degrees: function(radians)
    {
      let pi = Math.PI;
      return radians * (180/pi);
    },
    nauticalMileToMetre: function(val){
      return val * 1852
    },
    clickAction: function(mapIdx, type, idField, id){
      this.selectedPilotType = type
      this.selectedPilotMapIdx = mapIdx
      this.selectedPilotIdField = idField
      this.selectedPilotId = id
      this.dialogState = true
    },
    updateWeatherTimestamp: function(){
      fetch("https://tilecache.rainviewer.com/api/maps.json")
        .then(response => response.json())
        .then(data =>{
          this.latestWeatherTS = data[data.length - 1]
        })
    }
  }
}
</script>

<style scoped>

</style>
