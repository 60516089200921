<template>
  <div class="text-center">
    <v-dialog v-model="internalDialogState" width="800">
      <v-card v-if="pilot !== null">
        <div>
          <hr>
          <h1 style="text-align: center">Flight Info</h1>
          <hr>
        </div>
        <v-card-text>
          <v-container>
            <v-row v-if="originDestinationBlock != null">
              <div style="text-align: center">
                <h1>{{originDestinationBlock['origin']['icao']}}</h1>
              </div>
              <div style="flex: 1 1 0; text-align: center">
                <template v-if="origin != null && destination != null">
                  <template v-if="pilot.type === 'a32nx'">
                    <h4 style="text-align: center">Distance: {{totalDistance.toFixed(2)}}nm</h4>
                    <v-progress-linear :value="100" striped height="10" style="width: auto; margin-left: 5vw; margin-right: 5vw"></v-progress-linear>
                  </template>
                  <template v-else>
                    <h4 style="text-align: center">Distance: {{distanceCovered.toFixed(2)}} / {{totalDistance.toFixed(2)}}nm</h4>
                    <v-progress-linear :value="percentProgress" striped height="10" style="width: auto; margin-left: 5vw; margin-right: 5vw"></v-progress-linear>
                    <h4 style="text-align: center; margin-top: .5rem;">ETE {{estimatedTimeRemaining}}</h4>
                  </template>
                </template>
                <template v-else>
                  <h4>...</h4>
                </template>
              </div>
              <div style="text-align: center;">
                <h1>{{originDestinationBlock['destination']['icao']}}</h1>
              </div>
            </v-row>
            <v-row style="margin-top: 1rem">
              <div v-if="pilot.type === 'tfsk'">
                  <p>Username: {{pilot['Username']}}</p>
                  <p>Ground Speed: {{pilot['Data']['ground_speed'].toFixed(2)}} Kts </p>
                  <p>Altitude: {{pilot['Data']['altitude'].toFixed(0)}} ft </p>
                  <p>Heading: {{pilot['Data']['heading_mag'].toFixed(0)}}&#176;</p>
              </div>
              <div v-else-if="pilot.type === 'vatsim'" >
                  <p>Pilot: {{pilot['name']}}</p>
                  <p>Callsign: {{pilot['callsign']}}</p>
                  <p>Ground Speed: {{pilot['groundspeed']}} Kts</p>
                  <p>Altitude: {{pilot['altitude']}} ft</p>
                  <p>Heading: {{pilot['heading']}}&#176;</p>
              </div>
              <div v-else-if="pilot.type === 'a32nx'" >
                <p>Flight: {{pilot['flight']}}</p>
                <p>Altitude: {{pilot['trueAltitude']}} ft</p>
                <p>Heading: {{pilot['heading']}}&#176;</p>
              </div>
            </v-row>
            <v-row v-if="activeFlight != null" style="justify-content: center;">
              <v-btn class="mr-2" color="warning" v-on:click="openShare(activeFlight['id'])">View Detail</v-btn>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="internalDialogState = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Shared from "./Shared";

export default {
  name: "LiveMapModal",
  props: ['pilot', 'dialogState'],
  data: function(){
    return {
      activeFlight: null,
      origin: null,
      destination: null
    }
  },
  computed: {
    internalDialogState: {
      get: function () {
        return this.dialogState
      },
      set: function (val) {
        this.$emit('update:dialogState', val)
      }
    },
    originDestinationBlock: function(){
      let returnData = {}

      let origin, destination
      if (this.pilot.type === 'tfsk'){
        if (this.activeFlight != null){
          origin = this.activeFlight['origin']
          destination = this.activeFlight['destination']
        }else{
          return null
        }
      } else if (this.pilot.type === 'vatsim'){
        origin = this.pilot['flight_plan']['departure']
        destination = this.pilot['flight_plan']['arrival']
      } else if (this.pilot.type === 'a32nx'){
        origin = this.pilot['origin']
        destination = this.pilot['destination']
      }

      if (origin === "" || destination === ""){
        return null
      }

      returnData['origin'] = {"icao": origin}
      returnData['destination'] = {"icao": destination}

      this.getAirportData(origin, destination)

      return returnData
    },
    totalDistance: function(){
      return Shared.methods.calcDistance(this.origin['airport_ref_latitude'], this.origin['airport_ref_longitude'], this.destination['airport_ref_latitude'], this.destination['airport_ref_longitude'])
    },
    lastPosition: function(){
      if (this.pilot.type === 'tfsk'){
        return [this.pilot['Data']['latitude'], this.pilot['Data']['longitude']]
      } else if (this.pilot.type === 'vatsim'){
        return [this.pilot['latitude'], this.pilot['longitude']]
      }
    },
    remainingDistance: function(){
      return Shared.methods.calcDistance(this.lastPosition[0], this.lastPosition[1], this.destination['airport_ref_latitude'], this.destination['airport_ref_longitude'])
    },
    distanceCovered: function(){
      return Math.max(this.totalDistance - this.remainingDistance,0)
    },
    percentProgress: function(){
      return ((this.totalDistance - this.remainingDistance) / this.totalDistance) * 100
    },
    estimatedTimeRemaining: function (){
      let groundspeed = 0
      if (this.pilot.type === 'tfsk'){
        groundspeed = this.pilot['Data']['ground_speed']
      } else if (this.pilot.type === 'vatsim'){
        groundspeed = this.pilot['groundspeed']
      }
      return Shared.methods.formatHoursToTime(this.remainingDistance / groundspeed)
    }
  },
  watch: {
    pilot: function(val){
      if (val === null || val.type !== 'tfsk'){
        return
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      }

      fetch(this.$baseURL+"/"+val['Username']+"/activeFlight", requestOptions)
        .then(response => {
          if (response.ok){
            return response.json().then(data => {
              if (data !== null) {
                this.activeFlight = Object.assign({}, data)
              }else{
                this.activeFlight = null
              }
            })
          }else{
            this.activeFlight = null
          }
        })
    },
    internalDialogState: function(val){
      if (!val){
        this.activeFlight = null
        this.origin = null
        this.destination = null
      }
    }
  },
  methods: {
    openShare: function(flightID){
      this.$router.push({name: "FlightShare", params: {flightID: flightID}})
    },
    getAirportData: function (origin, dest){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      }

      fetch(this.$baseURL+"/airport?airport="+origin+","+dest, requestOptions)
        .then(response => {
          if (response.ok){
            return response.json().then(function (data){
              this.origin = data[origin]
              this.destination = data[dest]

            }.bind(this))
          }
        })

    }
  }
}
</script>

<style scoped>

</style>
